#localbodyreport td:nth-child(1),
#localbodyreport td:nth-child(3),
#localbodyreport td:nth-child(4),
#localbodyreport td:nth-child(5),
#localbodyreport td:nth-child(6),
#localbodyreport td:nth-child(7),
#localbodyreport td:nth-child(8),
#localbodyreport td:nth-child(9),
#localbodyreport td:nth-child(10),
#localbodyreport td:nth-child(11),
#localbodyreport td:nth-child(12),
#localbodyreport td:nth-child(13),
#localbodyreport td:nth-child(14),
#localbodyreport td:nth-child(15),
#localbodyreport td:nth-child(16) {
    text-align: center;
}
#localbodyreport thead:nth-child(1){
    margin-left: 20px;
}
.pagination-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    
    .pagination-controls {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  
    .pagination-button {
      min-width: 35px;
      height: 35px;
      padding: 0 10px;
      border: 1px solid #dee2e6;
      background-color: #fff;
      color: #556ee6;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
  
      &:hover:not(:disabled) {
        background-color: #556ee6;
        color: #fff;
        border-color: #556ee6;
      }
  
      &.active {
        background-color: #556ee6;
        color: #fff;
        border-color: #556ee6;
      }
  
      &:disabled {
        background-color: #f8f9fa;
        color: #adb5bd;
        cursor: not-allowed;
      }
    }
  
    .pagination-info {
      margin-top: 10px;
      color: #74788d;
      font-size: 14px;
    }
  }