#table_fund_transfer td:nth-child(5){
    text-align: right;
    width: 0.1rem;
  }
  #table_fund_transfer td:nth-child(6){
    text-align: left;
    width: 200px;
  }
#table_fund_transfer td:nth-child(7){
    text-align: left;
    width: 0.1rem;
  }
  #table_fund_transfer th{
    text-align: center;
  }
  #table_fund_transfer td:nth-child(1){
    text-align: center;
    width: 0.1rem;

  }
  #table_fund_transfer td:nth-child(2){
    text-align: center;
    width: 0.1rem;
  }
  #table_fund_transfer td:nth-child(3){
    text-align: left;
    width: 0.1rem;
  }
  #table_fund_transfer td:nth-child(4){
    text-align: left;
    width: 0.1rem;
  }
  #table_fund_transfer td:nth-child(8){
    text-align: center;
    width: 100px;
  }
  #table_fund_transfer td:nth-child(9){
    text-align: center;
    padding: 0px !important;
   // width: 0.1rem;
  }