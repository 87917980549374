
@media (min-width: 1200px) {
    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333%;
    }
}

@media (min-width: 1200px) {
    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66667%;
    }
}
#userVisitlogDataId th{
    text-align: center;
}
#userVisitlogDataId td:nth-child(1),
#userVisitlogDataId td:nth-child(2),
#userVisitlogDataId td:nth-child(3)
{
    text-align: center;
}
#userVisitlogDataId td:nth-child(5){
    text-align: right;
}
#userReceiptId th{
    text-align: center;
}
#userReceiptId td:nth-child(1),
#userReceiptId td:nth-child(2),
#userReceiptId td:nth-child(3){
    text-align: center;
}
#userReceiptId td:nth-child(4),
#userReceiptId td:nth-child(5){
    text-align: right;
}
#userReceiptId td:nth-child(2){
    min-width: 100px;
}
#userAccbalanceId th{
    text-align: center;
}
#userAccbalanceId td:nth-child(1),
#userAccbalanceId td:nth-child(2){
    text-align: center;
}
#userAccbalanceId td:nth-child(6),
#userAccbalanceId td:nth-child(7),
#userAccbalanceId td:nth-child(8){
    text-align: right;
}
#userVisitlogDataId td:nth-child(2){
    min-width: 97px;
}
#userAccbalanceId td:nth-child(2){
    min-width: 97px;
}
#userAccbalanceId td:nth-child(4){
    max-width: 120px;
}
#userAccbalanceId td:nth-child(5){
    max-width: 110px;
}
#userIncentive td:nth-child(1),
#userIncentive td:nth-child(2),
#userIncentive td:nth-child(3){
    text-align: center;
}
#userIncentive td:nth-child(6){
    text-align: right;
}
#userIncentive td:nth-child(8){
    text-align: left;
}