.row {
    --bs-gutter-x: 1.25rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #f6f6f6;
    border-radius: 0.25rem;
    margin-bottom: 1.25rem;
}

.body {
    margin: 0;
    font-family: IBM Plex Sans, sans-serif;
    font-family: var(--bs-body-font-family);
    font-size: 0.9rem;
    font-size: var(--bs-body-font-size);
    font-weight: 400;
    font-weight: var(--bs-body-font-weight);
    line-height: 1.5;
    line-height: var(--bs-body-line-height);
    color: #495057;
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: #f5f6f8;
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h5 {
    font-size: 1.125rem;
    color: #495057;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: #74788d !important;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

@media (min-width: 1200px) {
    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333%;
    }
}

@media (min-width: 1200px) {
    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66667%;
    }
}

#customerTableId th {
    text-align: center;
}

#customerTableId td:nth-child(1),
#customerTableId td:nth-child(2),
#customerTableId td:nth-child(4) {
    text-align: center;
}

#customerTableId td:nth-child(6),
#customerTableId td:nth-child(7) {
    text-align: right;
}

.pagination {
    position: relative;
    z-index: 0;
}

#to_date {
    line-height: 1.6rem;
}

#from_date {
    line-height: 1.6rem;
}

.pagination-div {
    display: flex;
    justify-content: space-between;
    /* To create space between the Pagination and page-details */
    align-items: center;
    /* Align items vertically in the center */
}

.Mui-selected {
    /* background: #dee3fb; */
    color: #fff !important;
    background-color: #5b73e8 !important;
    border-color: #5b73e8 !important;
}

.MuiButtonBase-root {
    min-width: 30px !important;
    height: 30px !important;
    border-radius: 0px !important;
}

.loader-container.box {
    position: sticky !important;
}

.loader.box {
    background-color: white !important;
    z-index: 1;
}

.loader .spinner {
    border-top-color: #64b946 !important
}

.loader.box .message,
.loader.body .message {
    color: #282828 !important;
}