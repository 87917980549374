
#staffComplaintId th{
    text-align: center;
}
#staffComplaintId td:nth-child(1),
#staffComplaintId td:nth-child(2),
// #staffComplaintId td:nth-child(3),
#staffComplaintId td:nth-child(9),
#staffComplaintId td:nth-child(11){
    text-align: center;
}
#staffComplaintId td:nth-child(7){
   max-width: 130px;
}
#staffComplaintId td:nth-child(8){
    text-align: center;
 }
#staffComplaintId td:nth-child(2){
    min-width: 97px;
 }
#staffcomplaintPopupTableDataId th{
    text-align: center;
}
#staffcomplaintPopupTableDataId td:nth-child(1){
    text-align: center;
    width: 80px;
}
#staffcomplaintPopupTableDataId td:nth-child(2){
    text-align: center;
    width: 100px;
}
#staffcomplaintPopupTableDataId td:nth-child(3){
    text-align: center;
    width: 100px;
}
#staffcomplaintPopupTableDataId td:nth-child(4){
    text-align: left;
    width: 210px;
}
#staffcomplaintPopupTableDataId td:nth-child(5){
    text-align: center;
    width: 100px;

}
#staffcomplaintPopupTableDataId td:nth-child(6){
    text-align: left;
    width: 100px;
}

#staffComplaintId td:nth-child(2){
    width: 100px;
    text-align: center;

}
#staffComplaintId td:nth-child(3){
    width: 100px;
    text-align: left;
}
#staffComplaintId td:nth-child(4){
    width: 120px;
    text-align: center;
}

