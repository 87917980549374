#intimationId {
  th{
    text-wrap: nowrap;
  }
  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4){
    text-wrap: nowrap;

  }
  td:nth-child(1){
    text-align: center;

  }
  td:nth-child(7),
  td:nth-child(8){
    text-align: center;

  }
}