

.txt-fields {
    @media only screen and (min-width: 1280px) {
        margin-left: 5%;
    }
}
#IncentivepopupTableID td:nth-child(5),
#IncentivepopupTableID td:nth-child(8){
    text-align: right;
}

#incentiveTable td:nth-child(4),
#incentiveTable td:nth-child(6){
    text-align: right;
   // width: 137px;
}
#incentiveTable td:nth-child(1),
#incentiveTable td:nth-child(8){
    text-align: center;
}
#incentiveTable td:nth-child(1),
#incentiveTable td:nth-child(2),
#incentiveTable td:nth-child(3),
#incentiveTable td:nth-child(5),
#incentiveTable td:nth-child(8){
    //width:100px;
}
#incentiveTable td:nth-child(2){
    min-width: 200px;
}

