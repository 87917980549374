#manageNotificationTableId th {
  text-align: center;

}

#manageNotificationTableId td:nth-child(1) {
  text-align: center;
  width: 80px;
}

#manageNotificationTableId td:nth-child(2) {
  text-align: center;
  width: 100px;
}

#manageNotificationTableId td:nth-child(3) {
  text-align: center;
  width: 100px;
}

#manageNotificationTableId td:nth-child(4) {
  text-align: left;
  width: 150px;
}

#manageNotificationTableId td:nth-child(5) {
  text-align: left;
  width: 420px;
}

#manageNotificationTableId td:nth-child(6) {
  text-align: center;
  width: 100px;
}

#manageNotificationTableId td:nth-child(7) {
  text-align: center;
  width: 100px;
}

#manageNotificationpopupTableId td:nth-child(1) {
  text-align: center;
  width: 100px;
}

// #manageNotificationTableId td:nth-child(2),
// #manageNotificationTableId td:nth-child(3) {
//   text-align: center;
// }