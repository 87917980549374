#addWasteId th{
    text-align: center;
    text-wrap: nowrap
};
#addWasteId td:nth-child(1),
#addWasteId td:nth-child(5),
#addWasteId td:nth-child(6),
#addWasteId td:nth-child(8){
    text-align: center;
};
#addWasteId td:nth-child(3){
    min-width: 120px;};

#addWasteId td:nth-child(9),
#addWasteId td:nth-child(10){
    text-align: end;
    
};
