#checkInOutTable th,
#checkInOutPopupTable th{
  text-align: center;
}
#checkInOutPopupTable td:nth-child(1),
#checkInOutPopupTable td:nth-child(2),
#checkInOutPopupTable td:nth-child(3),
#checkInOutPopupTable td:nth-child(4),
#checkInOutPopupTable td:nth-child(9),
#checkInOutTable td:nth-child(1),
#checkInOutTable td:nth-child(3),
#checkInOutTable td:nth-child(5),
#checkInOutTable td:nth-child(8),
#checkInOutTable td:nth-child(9),
#checkInOutTable td:nth-child(10){
  text-align: center;
}
#checkInOutTable td:nth-child(3),
#checkInOutTable td:nth-child(5),
#checkInOutTable td:nth-child(9),
#checkInOutTable td:nth-child(10){
    width: 100px;
}

#checkInOutTable td:nth-child(7),
#checkInOutTable td:nth-child(8){
    width: 80px;
}