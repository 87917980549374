#AllSlipTable th{
  text-align: center;
}

#AllSlipTable td:nth-child(1),
#AllSlipTable td:nth-child(3),
#AllSlipTable td:nth-child(13) {
  text-align: center;
}
#AllSlipTable td:nth-child(7),
#AllSlipTable td:nth-child(8),
#AllSlipTable td:nth-child(9),
#AllSlipTable td:nth-child(10),
#AllSlipTable td:nth-child(11),
#AllSlipTable td:nth-child(12){
  text-align: right;
}
.printable { display: none; }
.non-printable { display: block; }
@media print
{
    .non-printable { display: none; }
    .printable { display: block;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      margin: 0; }
}