#sliderTableId th{
    text-align: center;
   
    
}
#sliderTableId td:nth-child(1){
    text-align: center;
    width:1px;
}
#sliderTableId td:nth-child(2){
    text-align: center;
    width:100px;
}
#sliderTableId td:nth-child(3){
    text-align: center;
    width:100px;
}
#sliderTableId td:nth-child(4){
    text-align: left;
    width:110px;
}

#sliderTableId td:nth-child(5){
    text-align: left;
    width:120px;
}
#sliderTableId td:nth-child(6){
    text-align: center;
    width:110px;
}
#sliderTableId td:nth-child(7){
    text-align: left;
    max-width:110px;
    
}
#sliderTableId td:nth-child(8){
    text-align: left;
    width:100px;
    
}
#sliderTableId td:nth-child(9){
    text-align: center;
    width:90px;
    
}