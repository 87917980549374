#addWorkRequestID td:nth-child(1)
// #addWorkRequestID td:nth-child(2),
// #addWorkRequestID td:nth-child(3)
{
    text-align: center;
    width: 40px;
}

#addWorkRequestID td:nth-child(3){
    width: 100px;
    text-align: center;
}
#addWorkRequestID td:nth-child(7){
    text-align: left;
    width: 100px;
    //padding: 0px !important;
}
#addWorkRequestID td:nth-child(2){
    text-align: center;
    width: 120px;
}
#addWorkRequestID td:nth-child(4){
    width: 150px;
    text-align: left;
}
#addWorkRequestID td:nth-child(5){
    width: 230px;
    text-align: left;
}
#addWorkRequestID td:nth-child(6){
    width: 200px;
    text-align: left;
}
#addWorkRequestID td:nth-child(8){
    width: 100px;
    text-align: center;
}

