#verifyCustomerId{
  th{
    text-wrap: nowrap;
  }
  td{
    text-wrap: nowrap;
  }
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(1)
  {
    text-align: center;
  }
}