#complainstId{

  td:nth-child(6){
    max-width: 212px;
  }
  td:nth-child(2){
 text-wrap: nowrap;
  }
  td:nth-child(4){
   width: 130px;
  }
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(4)
  {
  text-align: center;
  }
}

.lead-details {
  display: flex;
  flex-wrap: wrap;
  gap: .9rem 2.5rem;
  list-style-type: none;
  margin-bottom: 0;
  padding: 1rem 1rem .3rem;
}

#complaintPopupTableDataId{
  th{text-align: center;}

  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(5),
  td:nth-child(6),
  td:nth-child(3){
    text-align: center;
  }
}