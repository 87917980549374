#addCreditnoteTableID th{
    text-align: center;
}
#addCreditnoteTableID td:nth-child(1),
#addCreditnoteTableID td:nth-child(2),
#addCreditnoteTableID td:nth-child(3),
#addCreditnoteTableID td:nth-child(4),
#addCreditnoteTableID td:nth-child(5),
#addCreditnoteTableID td:nth-child(7),
#addCreditnoteTableID td:nth-child(8),
#addCreditnoteTableID td:nth-child(9){
    text-align: center;
}
#addCreditnoteTableID td:nth-child(2),
#addCreditnoteTableID td:nth-child(3){
   min-width: 97px;
}
#addCreditnoteTableID td:nth-child(6){
    max-width: 210px;
 }
