#helperCollectionId td:nth-child(1),
#helperCollectionId td:nth-child(2),
#helperCollectionId td:nth-child(3){
    text-align: center;
}
#helperCollectionId td:nth-child(2){
   min-width: 97px;
}
// #helperCollectionId td:nth-child(3){
//     min-width: 118px;
//  }
 #helperCollectionId td:nth-child(7){
    max-width: 130px;
 }
 #helperCollectionId td:nth-child(6){
    max-width: 115px;
 }
#helperCollectionId th{
    text-align: center;
}
#helperCollectionId td:nth-child(8){
    text-align: right;
}
#wasteCollectionItemDetailId th{
    text-align: center;
}
#wasteCollectionItemDetailId td:nth-child(1),
#wasteCollectionItemDetailId td:nth-child(4),
#wasteCollectionItemDetailId td:nth-child(5){
    text-align: center;
}
#wasteCollectionItemDetailId td:nth-child(3),
#wasteCollectionItemDetailId td:nth-child(6){
    text-align: right;
}
.base-TablePagination-toolbar {
    padding: 1rem;
    min-width: 100% !important;
    display: flex !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: row !important;
    -webkit-align-items: space-around !important;
    -webkit-box-align: space-around !important;
    -ms-flex-align: space-around !important;
    align-items: center !important;
    justify-content: space-around !important;
    background-color: #fff !important;
    flex-wrap: wrap;
  }
  
  .MuiPagination-ul {
    font-size: 0.8rem;
  }
  
  #paginationComponent {
    display: flex;
    justify-content: center;
  }
  