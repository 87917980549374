#wastecollectionId th{
    text-align: center;
}
#wastecollectionId td:nth-child(1),
#wastecollectionId td:nth-child(2),
#wastecollectionId td:nth-child(3),
 #wastecollectionId td:nth-child(4)
// #wastecollectionId td:nth-child(5)
{
    text-align: center;
}
#wastecollectionId td:nth-child(5)
{
    text-align: left;
}
#wastecollectionId td:nth-child(6)
{
    text-align: left;
    max-width: 0.1px;
}
#wastecollectionId td:nth-child(8)
{
    text-align: left;
    max-width: 0.1px;
}
#wasteCollectionItemDetailsId th{
    text-align: center;
}
#wasteCollectionItemDetailsId td:nth-child(1),
#wasteCollectionItemDetailsId td:nth-child(3),
#wasteCollectionItemDetailsId td:nth-child(4){
    text-align: center;
}
#wasteCollectionItemDetailsId td:nth-child(5){
    text-align: right;
}
