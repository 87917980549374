#recieptTableId td:nth-child(8){
text-align: right;
}
#recieptTableId th{
    text-align: center;
}
#recieptTableId td:nth-child(1),
#recieptTableId td:nth-child(2),
#recieptTableId td:nth-child(3),
#recieptTableId td:nth-child(4){
    text-align: center;
}
#recieptTableId td:nth-child(5),
#recieptTableId td:nth-child(11){
    text-align: left;
}
#recieptTableId td:nth-child(6){
    text-align: right;
}