#baleTableId th {
    text-align: center;
}

#baleTableId td:nth-child(1),
#baleTableId td:nth-child(2),
#baleTableId td:nth-child(5) {
    text-align: center;
}

.btnlist {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}