#groupwisewastereportId th {
    text-align: center;
}

#groupwisewastereportId td:nth-child(1),
#groupwisewastereportId td:nth-child(3),
#groupwisewastereportId td:nth-child(4),
#groupwisewastereportId td:nth-child(5),
#groupwisewastereportId td:nth-child(6){
    text-align: center;
}
#groupwisewastereportId td:nth-child(1){
    text-align: center;
}
#groupwisewastereportId td:nth-child(2){
    text-align: left;
}