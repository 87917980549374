#ware-house-intimation th {
    text-align: center;
}

#ware-house-intimation td:nth-child(4),
#ware-house-intimation td:nth-child(5),
#ware-house-intimation td:nth-child(6),
#ware-house-intimation td:nth-child(7),
#ware-house-intimation td:nth-child(8) {
    text-align: right;
}