

.txt-fields {
    @media only screen and (min-width: 1280px) {
        margin-left: 5%;
    }
}
#incentivelistTable td:nth-child(1),
#incentivelistTable td:nth-child(2),
#incentivelistTable td:nth-child(3),
#incentivelistTable td:nth-child(4),
#incentivelistTable td:nth-child(5),
#incentivelistTable td:nth-child(6){
    text-align: center;
}