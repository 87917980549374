// #wasteReportCustId td:nth-child(10),
// #wasteReportCustId td:nth-child(11) {
//   text-align: center;
// }

#wasteReportCustId th:nth-child(1),
#wasteReportCustId th:nth-child(2),
#wasteReportCustId th:nth-child(3),
#wasteReportCustId th:nth-child(4),
#wasteReportCustId th:nth-child(5),
#wasteReportCustId th:nth-child(6),
#wasteReportCustId th:nth-child(7){
  text-align: center;
}
#wasteReportCustId td:nth-child(1),
#wasteReportCustId td:nth-child(3),
#wasteReportCustId td:nth-child(8),
#wasteReportCustId td:nth-child(9){
  text-align: center;
}

#wasteReportCustId td:nth-child(7){
  max-width: 210px;
}
#wasteReportCustId td:nth-child(4),
#wasteReportCustId td:nth-child(5){
  max-width: 160px;
}

