#addRequestTableId th {
    text-align: center;
} 
#addRequestTableId td:nth-child(1),
#addRequestTableId td:nth-child(2),
#addRequestTableId td:nth-child(3){
    text-align: center;
}
#addRequestTableId td:nth-child(2),
#addRequestTableId td:nth-child(3){
    min-width: 97px;
}
