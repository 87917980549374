.main__chatlist {
  border-right: 1px solid #ebe7fb;
  padding: 0 40px 0 0px;
}
.chatlist__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-nobg {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  color: #dad9dd;
  outline: none;
}
.search_wrap {
  background-color: #e6e5ea;
  border-radius: 5px;
}
.search_wrap input {
  background-color: transparent;
  border: none;
  padding: 15px 15px;
  outline: none;
  width: 80%;
  padding-right: 0;
}
.search-btn {
  height: 46px;
  border: none;
  background-color: transparent;
  outline: none;
  width: 20%;
  cursor: pointer;
  font-size: 20px;
}
.chatlist__items {
  margin-top: 30px;
  overflow: auto;
  max-height: calc(100vh - calc(100vh / 2));
}

.chatlist__item {
  display: flex;
  border-bottom: 1px solid #ebe7fb;
  padding-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  transform: scale(0);
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.1s; /* or: Xms */
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chatlist__item:first-child {
  margin-top: 0;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;

  margin-right: 20px;
  position: relative;
}
.avatar img {
  max-width: 100%;
  object-fit: cover;
}

.chatlist__item .userMeta p {
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: 600;
  font-size: 14px;
}
.chatlist__item .userMeta span {
  margin: 0;
  padding: 0;
  color: #ceccd3;
  font-weight: 400;
  font-size: 12px;
  display: block;
}
.chatlist__item:hover,
.chatlist__item.active {
  background: #fff;

  border-radius: 10px;
}
.isOnline {
  position: absolute;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ddd;
  border-radius: 50%;
  border: 2px solid #fff;
}
.isOnline.active {
  background-color: tomato;
}
.avatar-img {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.active-all {
  background-color: #1a71b5;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin: 10px 5px;
  font-size: 15px;
  text-align: center;

  color: #fff;
  cursor: pointer;
  border: 1px solid;
}
.active-all1 {
  display: block;
  margin-top: 5px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  list-style-type: none;
  white-space: nowrap;
}
.active-all2 {
  display: block;
  margin-top: 5px;
  font-weight: bold;
  color: #a2a2a2;
  text-align: center;
  list-style-type: none;
  white-space: nowrap;
}
.whatsapp {
  background-color: rgba(255, 255, 255, 0.75);
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin: 10px 5px;
  font-size: 15px;
  text-align: center;
  background-color: 1a71b5;
  color: #128c7e;
  cursor: pointer;
  border: 1px solid;
}
.facebook {
  background-color: #ebf2f7;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin: 10px 5px;
  font-size: 15px;
  text-align: center;
  color: #3b5998;
  cursor: pointer;
  border: 1px solid;
}
.instagram {
  background-color: #ebf2f7;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin: 10px 5px;
  font-size: 15px;
  text-align: center;
  background-color: 1a71b5;
  color: #3f729b;
  cursor: pointer;
  border: 1px solid;
}
.twitter {
  background-color: #ebf2f7;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin: 10px 5px;
  font-size: 15px;
  text-align: center;
  background-color: 1a71b5;
  color: #00acee;
  cursor: pointer;
  border: 1px solid;
}
.btn-recent {
  background-color: #fff !important;
  color: #4462ff !important;
}
.btn-recent:hover {
  background-color: #4462ff !important;
  color: #fff !important;
}

.whatsapp1 {
  background-color: rgba(255, 255, 255, 0.75);
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin: 10px 5px;
  font-size: 15px;
  text-align: center;
  background-color: 1a71b5;
  color: #128c7e;
  cursor: pointer;
  border: 1px solid;
}
.divider{
  border-left: 1px solid black;
}
.active{
  list-style-type: none;
}

/* .btn-create{
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: #1a71b5;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  outline: 0;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
} */
.btn-create, .btn-create:focus, .btn-create:focus:active {
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: #4462ff;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  outline: 0;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}