#advanceTable th{
  text-align: center;
}
#advanceTable td:nth-child(1),
#advanceTable td:nth-child(2),
#advanceTable td:nth-child(3){
  text-align: center;
}
#advanceTable td:nth-child(4){
  text-align: right;
}
#advanceTable td:nth-child(2),
#advanceTable td:nth-child(3){
  width: 100px;
}
#popuptable th,
#popuptable td{
  text-align: left;
}