#customercollectReportTableId td:nth-child(5),
#customercollectReportTableId td:nth-child(4){
    text-align: center;
}
#customercollectReportTableId td:nth-child(6),
#customercollectReportTableId td:nth-child(7),
#customercollectReportTableId td:nth-child(8),
#customercollectReportTableId td:nth-child(3){
   max-width: 180px;
   text-align: left;
}
#customercollectReportTableId td:nth-child(2),
#customercollectReportTableId td:nth-child(1),
#customercollectReportTableId td:nth-child(4){
    text-align: center;
 }
#customercollectReportTableId td{
    text-align: center;
   
}