#helperDropTableId th{
    text-align: center;
}
#helperDropTableId td:nth-child(1),

#helperDropTableId td:nth-child(3),
#helperDropTableId td:nth-child(5),
#helperDropTableId td:nth-child(6){
    text-align: center;
}
#helperDropTableId td:nth-child(4),
#helperDropTableId td:nth-child(7),
#helperDropTableId td:nth-child(8){
    text-align: left;
}
#helperDropTableId td:nth-child(2){
    text-align: center;  
}
#helperDropTableId td:nth-child(9){
    text-align: left;
    max-width: 100px;
}
#helperDropItemDetailId th{
    text-align: center;
}
#helperDropItemDetailId td:nth-child(1),
#helperDropItemDetailId td:nth-child(3),
#helperDropItemDetailId td:nth-child(4){
    text-align: center;
}
#helperDropItemDetailId td:nth-child(5){
    text-align: left;
}
