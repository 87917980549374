#attendanceSummaryTableId th {
    text-align: center;
}
#attendanceSummaryTableId td:nth-child(1),
#attendanceSummaryTableId td:nth-child(3),
#attendanceSummaryTableId td:nth-child(4),
#attendanceSummaryTableId td:nth-child(5){
    text-align: center;
}
#attendancePopupTableId th{
    text-align: center;
}
#attendancePopupTableId td:nth-child(1),
#attendancePopupTableId td:nth-child(2){
    text-align: center;
}
// #attendancePopupTableId td:nth-child(3){
//     text-align: left;
// }
.fc-media-screen{
    min-height: 451px;
}
.fc-scroller {
    overflow-y: hidden !important;
 }
//  .fc-event {
//     border-radius: 2px;
//     border: none;
//     cursor: move;
//     font-size: 0.8125rem;
//     margin: 0px 0px;
//     padding: 0px 0px;
//     // text-align: center;
// }
 a.fc-event, a.fc-event:hover{
    cursor: pointer;
 }
 #external-events {
    .external-event {
        text-align: center;
        // padding: 8px 16px;
        margin-left: 100px;
       
    }
}
#data{
    text-align: left;
}
#card1{
    max-height: 100%;
}
.fc-view-harness fc-view-harness-active{
    height: 425px;
}
.fc .fc-toolbar{
    margin-top: -19px;
}