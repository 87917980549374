#driverDropTableId th{
    text-align: center;
}
#driverDropTableId td:nth-child(1),
#driverDropTableId td:nth-child(2),
#driverDropTableId td:nth-child(3){
    text-align: center;
}
#driverDropItemDetailId th{
    text-align: center;
}
#driverDropItemDetailId td:nth-child(1),
// #driverDropItemDetailId td:nth-child(2),
#driverDropItemDetailId td:nth-child(3),
#driverDropItemDetailId td:nth-child(4){
    text-align: center;
}
#driverDropItemDetailId td:nth-child(5){
    text-align: left;
}