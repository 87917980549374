// #daywiseReportId td:nth-child(3),
// #daywiseReportId td:nth-child(5),
// #daywiseReportId td:nth-child(6){
//     text-align: right;
// }
#daywiseReportTableId td:nth-child(1),
#daywiseReportTableId td:nth-child(2),
#daywiseReportTableId td:nth-child(3),
#daywiseReportTableId td:nth-child(4),
#daywiseReportTableId td:nth-child(6),
#daywiseReportTableId td:nth-child(7),
#daywiseReportTableId td:nth-child(8),
#daywiseReportTableId td:nth-child(9),
#daywiseReportTableId td:nth-child(10) {
    text-align: center;
}

#daywiseReportTableId th{
    text-align: center;
}
#daywiseReportTableId td:nth-child(2){
   min-width: 100px;
}