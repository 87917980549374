#localbodyTableId th{
    text-align: center;
}
#localbodyTableId td:nth-child(1),
#localbodyTableId td:nth-child(2),
#localbodyTableId td:nth-child(3){
    text-align: center;
}
#localbodyTableId td:nth-child(2),
#localbodyTableId td:nth-child(3){
    min-width: 97px;
}
#localbodyTableId td:nth-child(6),
#localbodyTableId td:nth-child(7){
    max-width: 115px;
}
#localbodyTableId td:nth-child(9){
    max-width: 127px;
}