#qrcodeTableId th{
    text-align: center;
} 
#qrcodeTableId td:nth-child(1),
#qrcodeTableId td:nth-child(2),
#qrcodeTableId td:nth-child(3){
    text-align: center;
}
// #qrcodeTableId td:nth-child(4){
//     text-align: left;
// }