#userTableId th{
  text-align: center;
}
#userTableId td:nth-child(1),
#userTableId td:nth-child(2),
#userTableId td:nth-child(4){
  text-align: center;
}
#userTableId td:nth-child(8){
  text-align: center;
}
#userTableId td:nth-child(2){
  min-width: 100px;
}
// .dataTable{
//   background-color: #fff;
// }