#addTariffTableId th{
    text-align: center;
}
#addTariffTableId td:nth-child(1),
#addTariffTableId td:nth-child(4),
#addTariffTableId td:nth-child(5),
#addTariffTableId td:nth-child(6),
#addTariffTableId td:nth-child(7){
    text-align: center;
}
#tariffPopupTableId th{
    text-align: center;
}
#tariffPopupTableId td:nth-child(1),
#tariffPopupTableId td:nth-child(3){
    text-align: center;
}