.newappdashboard-table td,
.newappdashboard-table th {
    border: 1px solid #3333 !important;
    border-collapse: collapse !important;
    padding: 10px !important;
}

.newappdashboard-table {
    margin: auto !important;
}

.newappdashboard-table td:nth-child(1) {
    min-width: 170px;
}

.toCapitalize {
    text-transform: capitalize;
}

