#foodWasteRequestID th{
    text-align: center;
}
#foodWasteRequestID td:nth-child(1),
#foodWasteRequestID td:nth-child(2),
#foodWasteRequestID td:nth-child(3){
    text-align: center;
}
#foodWasteRequestID td:nth-child(2),
#foodWasteRequestID td:nth-child(3){
    min-width: 97px;
}
#foodWasteRequestID td:nth-child(7){
   max-width:180px ;
}
#foodWasteRequestID td:nth-child(5){
    max-width:165px ;
}
#foodWasteRequestID td:nth-child(8){
    text-align: left;
}    
#foodWasteRequestID td:nth-child(9){
   min-width: 120px;
}   