#viewTariffId td:nth-child(8){
    text-align: right;
};
#viewTariffId th{
    text-align: center;
};
#viewTariffId td:nth-child(1),
#viewTariffId td:nth-child(2),
#viewTariffId td:nth-child(3),
#viewTariffId td:nth-child(9){
    text-align: center;
}
#viewTariffId td:nth-child(2){
    min-width: 100px;
}