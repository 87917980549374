.header_responsive_dropdown {
  display: flex;
}

// .btn.filter-icon{
//     display: none;
//   }
//   span.dropdown-close {
//     display: none;
// }
// @media only screen and (max-width: 768px) {
//   .btn.filter-icon{
//     display: block;
//   }
//   .header_responsive_dropdown {
//     position: absolute;
//     width: 100%;
//    left:0;
//    top: 70px;
//     background-color: white;
//     z-index: 99;
//     flex-wrap: wrap;
//     display: none;
//     justify-content: center;
//     box-shadow: 0 11px 21px #00000021;
//     padding: 20px;
// }
// span.dropdown-close {
//   display: flex;
//   position: absolute;
//   right: 20px;
//   top: 10px;
// }
// }


@media only screen and (max-width: 768px) {

 .header_responsive_dropdown {
   position: absolute;
   width: 100%;
  left:0;
  top: 70px;
   background-color: white;
   z-index: 99;
   flex-wrap: wrap;
  
   justify-content: center;
   box-shadow: 0 11px 21px #00000021;
  
}


.page-content {
 padding-top: 170px!important;
}


}
@media only screen and (max-width: 420px) {
 .dropdown-header-responsive{
   width: 160px!important;
 }
}