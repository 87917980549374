#receiptId td:nth-child(7),
#receiptId td:nth-child(8)
{
    text-align: right;
};
#receiptId th{
    text-align: center;
};
#receiptId td:nth-child(1),
#receiptId td:nth-child(2){
    text-align: center;
};
#receiptId td:nth-child(2){
    min-width: 100px;
};
#table1 th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  #table1 td {
    border: 1px solid #dddddd;
    
    padding: 8px;
  }
  #table1 td:nth-child(1){
    text-align: left;
  }
  #table1 td:nth-child(5),
  #table1 td:nth-child(2){
    text-align: right;
  }
  #table1 th:nth-child(5){
    width: 220px;
  }
  #table1 td:nth-child(3),
  #table1 td:nth-child(4){
    text-align: center;
  }
  #table2 th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
    // width: 100%;
  }
  #table2 td {
    border: 1px solid #dddddd;
    
    padding: 8px;
  }