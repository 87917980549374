#foodWasteStatusLogID th{
    text-align: center;
}
#foodWasteStatusLogID td:nth-child(1),
#foodWasteStatusLogID td:nth-child(2),
#foodWasteStatusLogID td:nth-child(3){
    text-align: center;
}

#foodWasteStatusLogID td:nth-child(3){
    width: 180px;
    text-align: left;
}
#foodWasteStatusLogID td:nth-child(7){
    text-align: left;
}