#pendingTableId th {
    text-align: center;
}

#pendingTableId td:nth-child(1),
#pendingTableId td:nth-child(2),
#pendingTableId td:nth-child(5) {
    text-align: center;
}

#pendingTableId td:nth-child(12) {
    text-align: left;
}