#customerpaymentReportTableId td:nth-child(5),
#customerpaymentReportTableId td:nth-child(4){
    text-align: center;
}
#customerpaymentReportTableId td:nth-child(6),
#customerpaymentReportTableId td:nth-child(7),
#customerpaymentReportTableId td:nth-child(8),
#customerpaymentReportTableId td:nth-child(3){
   max-width: 180px;
   text-align: left;
}
#customerpaymentReportTableId td:nth-child(1),
#customerpaymentReportTableId td:nth-child(2),
#customerpaymentReportTableId td:nth-child(4){
    text-align: center;
}
#customerpaymentReportTableId td{
    text-align: right;
   
}