#salaryCalcTableId th:nth-child(2) {
    width: 15%;
}
#salaryCalcTableId td:nth-child(1) {
    text-align: center;
}
#salaryCalcTableId td:nth-child(7),
#salaryCalcTableId td:nth-child(8),
#salaryCalcTableId td:nth-child(9)
// #salaryCalcTableId td:nth-child(10),
// #salaryCalcTableId td:nth-child(11),
// #salaryCalcTableId td:nth-child(12)
{
    text-align: right;
}
#salaryCalcTableId td:nth-child(4),
#salaryCalcTableId td:nth-child(5),
#salaryCalcTableId td:nth-child(6){
    min-width: 10px;
    text-align: left;
}


#salaryCalcTableId td:nth-child(3) {
    max-width: 120px;
}
#salaryTable th{
    text-align: center;
}
#salaryTable td:nth-child(1),
#salaryTable td:nth-child(2),
#salaryTable td:nth-child(3),
#salaryTable td:nth-child(4),
#salaryTable td:nth-child(8){
    text-align: center;
}
#salaryTable td:nth-child(6){
    text-align: right;
}
#salarySlipTable th{
    text-align: center;
}
#salarySlipTable td:nth-child(1){
    text-align: center;
}
//#salarySlipTable td:nth-child(6),
#salarySlipTable td:nth-child(7),
#salarySlipTable td:nth-child(8),
#salarySlipTable td:nth-child(9),
#salarySlipTable td:nth-child(10),
#salarySlipTable td:nth-child(11){
    text-align: right;
}