#waterequestTableId th {
    text-align: center;
    width: 1px;


}

#waterequestTableId th:nth-child(7) {
    text-align: center;
    width: 1px;


}

#waterequestTableId th:nth-child(8) {
    text-align: center;
    width: 1px;


}

#waterequestTableId td:nth-child(1) {
    text-align: center;
    width: 0.1px;
}

#waterequestTableId td:nth-child(2) {
    text-align: center;
    // width:80px;
}

#waterequestTableId td:nth-child(3) {
    text-align: left;
    // width:100px;
}

#waterequestTableId td:nth-child(4) {
    text-align: left;
    width: 140px;
}

#waterequestTableId td:nth-child(5) {
    text-align: left;
    width: 130px;
}

#waterequestTableId td:nth-child(6) {
    text-align: right;
    width: 50px;
}

#waterequestTableId td:nth-child(7) {
    text-align: right;
    width: 0.1px;

}

#waterequestTableId td:nth-child(8) {
    text-align: right;
    width: 0.1px;

}

// #waterequestTableId td:nth-child(9){
//     text-align: center;
//     width:90px;

// }