#staffWalletDetailsTableId th {
    text-align: center;
}

#staffWalletDetailsTableId td:nth-child(1),
#staffWalletDetailsTableId td:nth-child(2),
#staffWalletDetailsTableId td:nth-child(3),
#staffWalletDetailsTableId td:nth-child(4),
#staffWalletDetailsTableId td:nth-child(5),
#staffWalletDetailsTableId td:nth-child(6) {
    text-align: center;
}

// #staffWalletDetailsTableId td:nth-child(2) {
//     min-width: 97px;
// }

#staffwalletTableId th {
    text-align: center;
}

#staffwalletTableId td:nth-child(1),
#staffwalletTableId td:nth-child(3),
#staffwalletTableId td:nth-child(4),
#staffwalletTableId td:nth-child(5),
#staffwalletTableId td:nth-child(6) {
    text-align: center;
}

.table-link-hover:hover {
    color: #5b73e8 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    text-underline-offset: 2px !important;
}

#customersTableId th {
    text-align: center;
}

#customersTableId td:nth-child(1),
#customersTableId td:nth-child(2),
#customersTableId td:nth-child(4) {
    text-align: center;
}

#customersTableId td:nth-child(3) {
    text-align: left;
}

.light-background {
    background-color: rgba(0, 0, 0, 0.5);
    /* Dark background color of your choice */
    // color: #000;
    /* Text color on dark background */
}

#paymentTableId th {
    text-align: center;
}

#paymentTableId td:nth-child(1),
#paymentTableId td:nth-child(4) {
    text-align: center;
}


