#OrderItemTable td, #OrderItemTable th{
    border: 1px solid #c0b8b8 !important;
}

.purchaseTotalTable td , 
.purchaseTotalTable th{
    padding: 10px;
    border: 1px solid #c0b8b8;
}
.purchaseTotalTable{
    width: 25%;
}

#purchasedetailtable td , 
#purchasedetailtable th{
 background-color: #fff;
}

#purchaseTableId td:nth-child(1),
#purchaseTableId td:nth-child(2),
#purchaseTableId td:nth-child(3),
#purchaseTableId td:nth-child(4),
#purchaseTableId td:nth-child(6) {
  text-align: center;
}

#stockDataTableId th {
  text-align: center;
}

#stockDataTableId td:nth-child(1),
#stockDataTableId td:nth-child(2),
#stockDataTableId td:nth-child(3),
#stockDataTableId td:nth-child(4),
#stockDataTableId td:nth-child(5) {
  text-align: center;
}

// ==================================================================================================================
// .plus-sign,
// .minus-sign {
//   margin: 0 5px; /* Add spacing between the signs */
//   cursor: pointer;
//   font-size: 20px; /* Adjust the font size as needed */
// }

// .plus-sign:hover,
// .minus-sign:hover {
//   color: blue; /* Change color on hover as desired */
// }

// .minus-sign, .plus-sign, .quan-qnty {
//   margin: 0px 10px;
//   display: inline-block;
// }