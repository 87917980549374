#autoInvoiceTableId th{
    text-align: center;
}
#autoInvoiceTableId td:nth-child(1),
#autoInvoiceTableId td:nth-child(2),
#autoInvoiceTableId td:nth-child(3),
#autoInvoiceTableId td:nth-child(4),
#autoInvoiceTableId td:nth-child(6){
    text-align: center;
    min-width: 95px;
}
#autoInvoiceTableId td:nth-child(9){
    text-align: right;
    max-width: 90px;
}
.table1 th{
    text-align: center;
}
.table th{
    text-align: center;
}
.table1 td{
    text-align: center;
}
.abc{
    text-align: right;
}
#autoInvoiceTableId td:nth-child(2){
    min-width:95px;
}
#autoInvoiceTableId td:nth-child(8){
    max-width: 110px;
}
#autoInvoiceTableId td:nth-child(7){
    max-width: 117px;
}