#warehouseId th {
    text-align: center;
}

#warehouseId td:nth-child(1),
#warehouseId td:nth-child(2),
#warehouseId td:nth-child(3),
#warehouseId td:nth-child(5),
#warehouseId td:nth-child(6) {
    text-align: center;
}