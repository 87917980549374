.tv-screen1-table td{
padding: 20px;
border-left: white thin solid;
border-right: white thin solid;
}
.tv-screen1-table td:nth-child(1){
    width:90%;
}
.tv-screen1-table td:nth-child(2){
    width:10%;
    font-weight: bold;
}
#tvwardListId th{
    text-align: center;
    font-size:10px;
}
#tvwardListId td{
    text-align: left;
    padding:6px;
    font-size:10px;
}
#tvwardListId td:nth-child(2),
#tvwardListId td:nth-child(3){
    width:95px
}
#tvwardListId td:nth-child(4){
    width:50px
}