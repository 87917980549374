
#daywisewastereportId th {
    text-align: center;
}
#daywisewastereportId td:nth-child(1),
#daywisewastereportId td:nth-child(2),
#daywisewastereportId td:nth-child(3),
#daywisewastereportId td:nth-child(4),
#daywisewastereportId td:nth-child(5),
#daywisewastereportId td:nth-child(6)
{
    text-align: center;
}
#daywisewastereportId td:nth-child(2){
   min-width: 97px;
}
#daywisewastereportId td:nth-child(7){
    max-width: 210px;
 }